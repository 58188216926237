<template lang="pug">
  Form.page-partner-customizations(:endpoint="`partners/${this.partner.id}/customisations`", method="PUT", :data="data", @success="onSave", button-text="SAVE CUSTOMISATIONS")

    div.mb-10.text-right: Button(type="primary", submit) SAVE CUSTOMIZATIONS

    Card(title="Media")
      Row(lg="row")
        Column(width="1/2")
          InputGroup(label="Default Logo"): ImageInput(v-model="data.logo")
        Column(width="1/2")
          InputGroup(label="Light Logo"): ImageInput(v-model="data.logo_light")

      Row(lg="row")
        Column(width="1/2")
          InputGroup(label="Logo Location")
            Radios(v-model="data.logo_location", inline, template="table", hide-input)
              Radio(value="header") Header
              Radio(value="contact-bar") Contact Bar
        Column(width="1/2")
          InputGroup(label="Favicon"): ImageInput(v-model="data.favicon")

    Card(title="Colors")
      Row(lg="row")
        Column(width="1/2")
          InputGroup(label="Brand Color", :description="`'inherit' or hexdecimal (default: ${parseColor(defaults.brand_color)})`")
            ColorInput(v-model="data.brand_color", :placeholder="parseColor(defaults.brand_color)")
        Column(width="1/2")
          InputGroup(label="Brand Hover Color", :description="`'inherit' or hexdecimal (default: ${parseColor(defaults.brand_hover_color)})`")
            ColorInput(v-model="data.brand_hover_color", :placeholder="parseColor(defaults.brand_hover_color)")

      Row(lg="row")
        Column(width="1/2")
          InputGroup(label="Link Color", :description="`default: ${parseColor(defaults.link_color)}`")
            ColorInput(v-model="data.link_color", :placeholder="parseColor(defaults.link_color)")
        Column(width="1/2")
          InputGroup(label="Link Hover Color", :description="`default: ${parseColor(defaults.link_hover_color)}`")
            ColorInput(v-model="data.link_hover_color", :placeholder="parseColor(defaults.link_hover_color)")

      Row(lg="row")
        Column(width="1/2")
          InputGroup(label="Headings Color", :description="`default: ${parseColor(defaults.heading_color)}`")
            ColorInput(v-model="data.heading_color", :placeholder="parseColor(defaults.heading_color)")
        Column(width="1/2")
          InputGroup(label="Body Color", :description="`default: ${parseColor(defaults.body_color)}`")
            ColorInput(v-model="data.body_color", :placeholder="parseColor(defaults.body_color)")

    Card(title="Typography")
      div.space-y-12
        div
          p.subtitle Collection Title
          div(class="grid form-grid lg:grid-cols-3")
            InputGroup(label="Font Type")
              Select(v-model="data.collection_title_font_type", :options="fontTypes")

            div(v-if="data.collection_title_font_type === 'google-font'")
              InputGroup(label="Font Family")
                AutoComplete(v-model="data.collection_title_google_font_id", endpoint="search/google-fonts", @selected="onCollectionTitleGoogleFontChange")
              InputGroup(v-if="data.collection_title_google_font_id", label="Font Weight", class="mt-6", name="collection_title_google_font_weight")
                Select(v-model="data.collection_title_google_font_weight", :endpoint="`google-fonts/${data.collection_title_google_font_id}/weights`")
            InputGroup(v-else, label="Font Family", :description="`default: ${defaults.collection_title_websafe_font_family}`")
              Select(v-model="data.collection_title_websafe_font_family", :options="websafeFonts")

            InputGroup(label="Font Size", :description="`default: ${defaults.collection_title_font_size}`")
              Select(v-model="data.collection_title_font_size", :options="fontSizes")

        div
          p.subtitle Content Titles
          div(class="grid form-grid lg:grid-cols-3")
            InputGroup(label="Font Type")
              Select(v-model="data.content_title_font_type", :options="fontTypes")

            div(v-if="data.content_title_font_type === 'google-font'")
              InputGroup(label="Font Family")
                AutoComplete(v-model="data.content_title_google_font_id", endpoint="search/google-fonts", @selected="onContentTitleGoogleFontChange")
              InputGroup(v-if="data.content_title_google_font_id", label="Font Weight", class="mt-6", name="content_title_google_font_weight")
                Select(v-model="data.content_title_google_font_weight", :endpoint="`google-fonts/${data.content_title_google_font_id}/weights`")
            InputGroup(v-else, label="Font Family", :description="`default: ${defaults.content_title_websafe_font_family}`")
              Select(v-model="data.content_title_websafe_font_family", :options="websafeFonts")

            InputGroup(label="Font Size", :description="`default: ${defaults.content_title_font_size}`")
              Select(v-model="data.content_title_font_size", :options="fontSizes")

        div
          p.subtitle Body
          div(class="grid form-grid lg:grid-cols-3")
            InputGroup(label="Font Type")
              Select(v-model="data.body_font_type", :options="fontTypes")

            div(v-if="data.body_font_type === 'google-font'")
              InputGroup(label="Font Family")
                AutoComplete(v-model="data.body_google_font_id", endpoint="search/google-fonts", @input="onBodyGoogleFontChange")
              InputGroup(v-if="data.body_google_font_id", label="Font Weight", class="mt-6", name="body_google_font_weight")
                Select(v-model="data.body_google_font_weight", :endpoint="`google-fonts/${data.body_google_font_id}/weights`")
            InputGroup(v-else, label="Font Family", :description="`default: ${defaults.body_websafe_font_family}`")
              Select(v-model="data.body_websafe_font_family", :options="websafeFonts")

            InputGroup(label="Font Size", :description="`default: ${defaults.body_font_size}`")
              Select(v-model="data.body_font_size", :options="fontSizes")

        div
          div(class="grid form-grid lg:grid-cols-3")
            div
              p.subtitle Header Message
              InputGroup(label="Font Size", :description="`default: ${defaults.header_message_font_size}`")
                Select(v-model="data.header_message_font_size", :options="fontSizes")

            div
              p.subtitle Heading Blocks
              InputGroup(label="Font Size", :description="`default: ${defaults.heading_block_font_size}`")
                Select(v-model="data.heading_block_font_size", :options="fontSizes")

            div
              p.subtitle Inspires Collection Title
              InputGroup(label="Font Size", :description="`default: ${defaults.inspires_collection_title_font_size}`")
                Select(v-model="data.inspires_collection_title_font_size", :options="fontSizes")

    Card(title="Header")
      div.space-y-12
        div
          Row(lg="row")
            Column(class="w-96")
              InputGroup(label="Background Image")
                ImageInput(v-model="data.header_background_image")
            Column
              InputGroup(label="Style")
                Radios(v-model="data.header_style", inline, template="table", hide-input)
                  Radio(value="light")
                    div.flex.items-center.text-center
                      span.bg-white.block.border.border-gray-400.mr-4.rounded.h-5.w-5
                      span Light
                  Radio(value="dark")
                    div.flex.items-center.text-center
                      span.bg-black.block.border.border-black.mr-4.rounded.h-5.w-5
                      span Dark

        div
          p.subtitle Overlay
          Row(lg="row")
            Column
              InputGroup(label="Style")
                Radios(v-model="data.header_overlay", inline, template="table", hide-input)
                  Radio(value="none")
                    span.flex.items-center.space-x-3
                      Icon(icon="ban")
                      span None
                  Radio(value="centered")
                    span.flex.items-center.space-x-3
                      Icon(icon="compress")
                      span Centered
                  Radio(value="full")
                    span.flex.items-center.space-x-3
                      Icon(icon="expand-wide")
                      span Full
            Column
              InputGroup(label="Gradient")
                Radios(v-model="data.header_overlay_background_style", inline, template="table", hide-input)
                  Radio(value="solid")
                    div.flex.items-center.text-center
                      span.bg-gray-400.block.mr-4.rounded.h-5.w-5
                      span Solid
                  Radio(value="linear-gradient")
                    div.flex.items-center.text-center
                      span.bg-gradient-to-t.from-gray-400.block.mr-4.rounded.h-5.w-5
                      span Blend
            Column
              InputGroup(label="Opacity")
                NumberInput(v-model="data.header_overlay_opacity")

    Card(title="Content")
      Row(lg="row")
        Column
          p.mb-4 Theme:
          InputGroup(label="Theme", :description="`default: ${defaults.theme}`")
            Select(v-model="data.theme", :options="themeOptions")

          p.mb-4 Layout:
          div.layout
            label.layout__option(@click="onLayoutChange('one-column')", :class="{'layout__option--selected': data.content_layout === 'one-column'}")
              div.layout__column

            label.layout__option(@click="onLayoutChange('two-columns')", :class="{'layout__option--selected': data.content_layout === 'two-columns'}")
              div.layout__column
              div.layout__column

            label.layout__option(@click="onLayoutChange('three-columns')", :class="{'layout__option--selected': data.content_layout === 'three-columns'}")
              div.layout__column
              div.layout__column
              div.layout__column
        Column
          p.mb-4 Appearance:
          div.space-y-4
            Checkbox(v-model="data.content_lead_in_enabled") Lead In
            Checkbox(v-model="data.content_runtime_enabled") Runtime
            Checkbox(v-model="data.content_author_enabled") Author
            Checkbox(v-model="data.content_synopsis_enabled") Synopsis
            Checkbox(v-model="data.content_image_enabled") Image (articles &amp; books only)
        Column
          p.mb-3 Preview:
          div.demo-content(:class="{'demo-content__has-featured-image': data.content_image_enabled}")
            div.demo-content__featured-image: img(src="@/assets/images/image-placeholder.png", v-show="data.content_image_enabled")
            div.demo-content__body
              p.demo-content__title(v-show="data.content_title_enabled") An Example Piece of Content
              p.demo-content__description
                strong.demo-content__lead-in(v-show="data.content_lead_in_enabled") View on Amazon
                span.demo-content__synopsis(v-show="data.content_synopsis_enabled") This text offers a brief synopsis (1-2 sentences) of the content.
                em.demo-content__author(v-show="data.content_author_enabled") By Author Name.
                em.demo-content__runtime(v-show="data.content_runtime_enabled") Runtime: X minutes.
              p(v-show="!data.content_image_enabled && !data.content_title_enabled && !data.content_lead_in_enabled && !data.content_runtime_enabled && !data.content_author_enabled && !data.content_synopsis_enabled") You've hidden all text. Only embedded media are visible.
</template>

<script>
import { endsWith, forEach, get } from "lodash-es"

export default {
  props: {
    partner: {}
  },
  metaInfo() {
    return { title: `${this.partner.name} Customizations`, bodyAttrs: { class: "page-partner-view-customizations" } }
  },
  data: () => ({
    defaults: {},
    data: {
      theme: null,
      logo: null,
      logo_light: null,
      favicon: null,
      brand_color: null,
      brand_hover_color: null,
      header_background_image: null,
      content_layout: null,
      header_overlay: null,
      header_overlay_background_style: null,
      header_overlay_opacity: null,
      header_style: null,
      heading_color: null,
      collection_title_font_type: null,
      collection_title_websafe_font_family: null,
      collection_title_google_font_id: null,
      collection_title_google_font_weight: null,
      collection_title_font_size: null,
      content_title_font_type: null,
      content_title_websafe_font_family: null,
      content_title_google_font_id: null,
      content_title_google_font_weight: null,
      content_title_font_size: null,
      body_color: null,
      body_font_type: null,
      body_websafe_font_family: null,
      body_google_font_id: null,
      body_google_font_weight: null,
      body_font_size: null,
      logo_location: null,
      header_message_font_size: null,
      heading_block_font_size: null,
      inspires_collection_title_font_size: null,
      link_color: null,
      link_hover_color: null,
      content_title_enabled: null,
      content_lead_in_enabled: null,
      content_runtime_enabled: null,
      content_author_enabled: null,
      content_synopsis_enabled: null,
      content_image_enabled: null
    },
    fontTypes: [
      { id: "websafe-font", name: "Websafe Font" },
      { id: "google-font", name: "Google Font" }
    ],
    themeOptions: [
      { id: "default", name: "Classic" },
      { id: "classic-grid", name: "Classic Grid" },
      { id: "modernello", name: "Modernello" }
    ],
    websafeFonts: [
      {
        label: "Default",
        items: [{ id: "inherit", name: "Inherit" }]
      },
      {
        label: "Sans Serif",
        items: [
          { id: "arial", name: "Arial" },
          { id: "arial-black", name: "Arial Black" },
          { id: "gill-sans", name: "Gill Sans" },
          { id: "helvetica", name: "Helvetica" },
          { id: "impact", name: "Impact" },
          { id: "tahoma", name: "Tahoma" },
          { id: "trebuchet", name: "Trebuchet MS" },
          { id: "verdana", name: "Verdana" }
        ]
      },
      {
        label: "Serif",
        items: [
          { id: "baskerville", name: "Baskerville" },
          { id: "georgia", name: "Georgia" },
          { id: "palatino", name: "Palatino" },
          { id: "timesnewroman", name: "Times New Roman" }
        ]
      },
      {
        label: "Monospace",
        items: [
          { id: "courier", name: "Courier" },
          { id: "lucida", name: "Lucida" },
          { id: "monaco", name: "Monaco" }
        ]
      },
      {
        label: "Cursive",
        items: [
          { id: "bradley-hand", name: "Bradley Hand" },
          { id: "brush-script-mt", name: "Brush Script MT" }
        ]
      }
    ],
    fontSizes: [
      { id: "xs", name: "Extra Small" },
      { id: "sm", name: "Small" },
      { id: "base", name: "Base" },
      { id: "lg", name: "Large" },
      { id: "xl", name: "XL" },
      { id: "2xl", name: "2 XL" },
      { id: "3xl", name: "3 XL" },
      { id: "4xl", name: "4 XL" },
      { id: "5xl", name: "5 XL" },
      { id: "6xl", name: "6 XL" },
      { id: "7xl", name: "7 XL" },
      { id: "8xl", name: "8 XL" },
      { id: "9xl", name: "9 XL" }
    ]
  }),
  async mounted() {
    let response = await this.$api.get(`partners/${this.$route.params.id}/customisations`)
    let customisations = get(response, "data.customisations", {})
    this.defaults = get(response, "data.defaults", {})

    forEach(this.data, (value, key) => {
      if (typeof customisations[key] !== "undefined" && customisations[key] !== "" && customisations[key] !== null) {
        value = customisations[key]
      } else if (typeof this.defaults[key] !== "undefined") {
        value = this.defaults[key]
      }

      if (endsWith(key, "_color") && value === "inherit") {
        value = null
      } else if (endsWith(key, "_size") && endsWith(value, "px")) {
        value = Number(value.substring(0, value.length - 2))
      }

      this.data[key] = value
    })
  },
  methods: {
    parseColor(color) {
      return color && color.replace("#", "")
    },
    onBodyGoogleFontChange() {
      this.data.body_google_font_weight = null
    },
    onCollectionTitleGoogleFontChange() {
      this.data.collection_title_google_font_weight = null
    },
    onContentTitleGoogleFontChange() {
      this.data.content_title_google_font_weight = null
    },
    onLayoutChange(layout) {
      this.data.content_layout = layout
    },
    onSave() {
      this.$notification.success("This partner's customizations have been saved.")
    }
  }
}
</script>

<style>
.page-partner-view-customizations {
  .card .row + .row {
    @apply mt-8;
  }

  .form-inputgroup:last-child {
    @apply mb-0;
  }

  .layout {
    @apply -mx-2 flex;

    &__option {
      @apply mx-2 flex w-12 cursor-pointer border border-gray-200;
      border-width: 2px;
      padding: 1px;
      transition: border-color 0.2s;

      .layout__column {
        @apply h-8 w-full bg-gray-200;
        margin: 1px;
        transition: background 0.2s;
      }

      &:hover {
        @apply border-gray-300;

        .layout__column {
          @apply bg-gray-300;
        }
      }

      &--selected {
        @apply cursor-default border-primary-default;

        .layout__column {
          @apply bg-primary-default;
        }

        &:hover {
          @apply border-primary-default;

          .layout__column {
            @apply bg-primary-default;
          }
        }
      }
    }
  }

  .demo-content {
    @apply flex rounded border p-8;
    border-width: 2px;
    max-width: 500px;

    &__featured-image {
      @apply mr-5 hidden flex-shrink-0;
      width: 50px;
    }

    &__title {
      @apply text-lg font-bold text-primary-default;
    }

    &__lead-in {
      @apply mr-2;
    }

    &__author,
    &__runtime {
      @apply ml-2;
    }

    &__has-featured-image {
      .demo-content__featured-image {
        @apply block;
      }
    }
  }

  .form__footer {
    @apply mt-10 justify-end;
  }

  .subtitle {
    @apply mb-6 border-b pb-3 font-medium text-heading;
  }

  .form-grid {
    @apply gap-8 lg:gap-12;

    .form-inputgroup {
      @apply mb-0;
    }
  }
}
</style>
