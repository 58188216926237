<template lang="pug">
  Row(lg="row")

    Column(lg="2/3")

      Card(title="Basic Information", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editDetails()")

        dl.dl-horizontal
          dt Name
          dd {{ partner.name }}
          dt Slug
          dd {{ partner.slug }}
          dt Contact email
          dd {{ partner.email }}
          dt Phone
          dd {{ partner.phone }}
          dt URL
          dd: a(v-if="partner.url", target="_blank", :href="partner.url") {{ partner.url }}
          dt Whitelist Domain(s)
          dd
            ul.list-comma-separated(v-if="partner.whitelist_domains")
              li(v-for="domain in partner.whitelist_domains") {{ domain }}
            span.text-danger(v-if="!partner.whitelist_domains") Embed disabled
          dt Same Tab Domain(s)
          dd
            ul.list-comma-separated(v-if="partner.same_tab_domains")
              li(v-for="domain in partner.same_tab_domains") {{ domain }}
            span(v-if="!partner.same_tab_domains") -

      Card(title="Premium", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editPermissions()")
        dl.dl-horizontal
          dt Enhanced SEO Features
          dd {{ partner.has_premium_seo ? 'Yes' : 'No' }}
          dt Itineraries
          dd {{ partner.has_premium_itineraries ? 'Yes' : 'No' }}
          dt Lead Generation / Surveys
          dd {{ partner.has_premium_forms ? 'Yes' : 'No' }}
          dt Premium Themes
          dd {{ partner.has_premium_themes ? 'Yes' : 'No' }}
          dt Travel Advisor Marketing
          dd {{ partner.has_premium_share ? 'Yes' : 'No' }}

      Card(title="Landing Page", :noPadding="true")
        dl.dl-horizontal
          dt PID
          dd {{ partner.alt_id }}
          dt Domain
          dd.text-monospaced {{ partner.landing_page_domain || '-' }}

      Card(title="API Information", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="clipboard", v-clipboard="`${partner.api_key}:${partner.api_secret}`", v-clipboard:success="onCopy")
          Button(prepend-icon="sync", @click="refreshApiKeys()", v-tooltip="'Refresh API keys'")

        dl.dl-horizontal
          dt API Key
          dd {{ partner.api_key }}
          dt API Secret
          dd {{ partner.api_secret }}
          dt Last Accessed
          dd {{ partner.last_api_activity_at | datetime }}

      Card(title="Inspires", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editInspires()")
        dl.dl-horizontal
          dt Logo URL
          dd.text-supplement Disabled
          //- dd {{ partner.inspires_logo_url }}
          dt Partner Details
          dd(v-html="$markdown(partner.inspires_partner_details)")
          dt CTAs
          dd {{ partner.inspires_allow_ctas ? 'Yes' : 'No' }}

      Card(title="AI", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editAi()")

        dl.dl-horizontal
          dt Brand Voice
          dd(:class="{'text-supplement': !partner.ai_brand_voice}") {{ partner.ai_brand_voice || '-' }}

    Column(lg="1/3")

      Card(title="Account", :noPadding="true", v-if="partner.account_type")
        dl.dl-horizontal
          dt Type
          dd: router-link(:to="{name: 'account-types.view.permissions', params: {id: partner.account_type.id}}") {{ partner.account_type.name }}

      Card(title="Meta", :noPadding="true")
        dl.dl-horizontal
          dt ID
          dd {{ partner.id }}
          dt Added On
          dd {{ partner.created_at | datetime }}
          dt Updated At
          dd {{ partner.updated_at | datetime }}
</template>

<script>
import EditPartnerModal from "@/modals/Partners/EditPartnerModal"
import EditPartnerAiModal from "@/modals/Partners/EditPartnerAiModal"
import EditPartnerInspiresModal from "@/modals/Partners/EditPartnerInspiresModal"
import EditPartnerPermissionsModal from "@/modals/Partners/EditPartnerPermissionsModal"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Details` }
  },
  props: {
    partner: {}
  },
  methods: {
    async editDetails() {
      if (await this.$modal.show(EditPartnerModal, { endpoint: `partners/${this.partner.id}` })) {
        this.$emit("reload")
      }
    },
    async editPermissions() {
      if (
        await this.$modal.show(EditPartnerPermissionsModal, {
          endpoint: `partners/${this.partner.id}/permissions`
        })
      ) {
        this.$emit("reload")
      }
    },
    async editAi() {
      if (
        await this.$modal.show(EditPartnerAiModal, {
          endpoint: `partners/${this.partner.id}/ai`
        })
      ) {
        this.$emit("reload")
      }
    },
    async editInspires() {
      if (
        await this.$modal.show(EditPartnerInspiresModal, {
          endpoint: `partners/${this.partner.id}/inspires`
        })
      ) {
        this.$emit("reload")
      }
    },
    async refreshApiKeys() {
      if (
        await this.$modal.confirm(
          "Are you sure you would like to refresh the API keys for this partner? In doing so, the previous keys will no longer work."
        )
      ) {
        await this.$api.put(`partners/${this.partner.id}/refresh-api-keys`, { confirm: true })
        this.$emit("reload")
      }
    },
    onCopy() {
      this.$notification.success("Copied!")
    }
  }
}
</script>
